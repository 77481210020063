<template>
    <div id="app-lesson" class="app-add">
        <div
                v-loading="form.loading"
                element-loading-text="提交中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.1)"
                style="width:100px; left:42%; top:46%; position: absolute"></div>

        <el-form :model="lesson" label-width="100px">
            <el-form-item label="课时名称" prop="name">
                <el-input v-model="lesson.name" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item label="课时类型" prop="type" style="display: none">
                <el-radio-group v-model="lesson.type">
                    <el-radio :label="0">点播</el-radio>
                    <el-radio :label="1">直播</el-radio>
                    <el-radio :label="2">直播补录</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="直播类型" prop="isPushLive" v-show="form.isShowLiveType">
                <el-radio v-model="lesson.isPushLive" :label="false">常规直播</el-radio>
                <el-radio v-model="lesson.isPushLive" :label="true">推流直播</el-radio>
            </el-form-item>

            <el-form-item label="上课时间" v-show="form.isShowDateTime">
                <el-date-picker
                        v-model="form.dateTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        :disabled="form.disableBaiJiaInfo"
                >
                </el-date-picker>
            </el-form-item>

            <el-form-item label="视频ID" v-show="form.isShowVideoId">
                <el-input type="number" v-model="lesson.videoId" placeholder="百家云视频ID"></el-input>
            </el-form-item>

            <el-form-item label="视频时长" v-show="form.isShowDuration">
                <el-input type="number" v-model="lesson.duration" placeholder="视频时长（秒）"></el-input>
            </el-form-item>

            <el-form-item label="选择讲师">
                <el-radio v-for="teacher in form.teachers" v-model="lesson.teacherId" :label="teacher.id" :disabled="form.disableBaiJiaInfo">
                    {{teacher.name}}
                </el-radio>
            </el-form-item>

            <el-form-item label="是否试听">
                <el-radio v-model="lesson.isFree" :label="false">否</el-radio>
                <el-radio v-model="lesson.isFree" :label="true">是</el-radio>
            </el-form-item>

            <el-form-item label="排序">
                <el-input type="number" v-model="lesson.sort" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item style="display: none">
                <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import { LessonTypeEnum } from "../../../public/js/common-vue";
    import {UrlEnum,TipEnum} from "../../../public/js/common-vue";
    import { request } from "../../../public/js/common-vue";

    export default {
        data() {
            return {
                form:{
                    loading: false,
                    isShowLiveType: false,
                    isShowDateTime: false,
                    isShowVideoId: true,
                    isShowDuration: false,
                    teachers: [],
                    dateTime: [new Date(), new Date()],
                    disableBaiJiaInfo:false
                },
                lesson: {}
            }
        },
        methods: {
            initData: function () {
                this.$http({
                    method: "get",
                    url: UrlEnum.LESSON + "/" + request('id'),
                }).then((res) => {
                    this.lesson = res.data;
                    const type = this.lesson.type;
                    if(type == LessonTypeEnum.VOD){
                        this.form.isShowDateTime = false;
                        this.form.isShowVideoId = true;
                        this.form.isShowDuration = true;
                    }
                    else if(type == LessonTypeEnum.LIVE){
                        this.form.isShowDateTime = true;
                        this.form.isShowVideoId = false;
                        this.form.isShowDuration = false;
                    }
                    else if(type == LessonTypeEnum.LIVE_SUPPLY){
                        this.form.isShowDateTime = true;
                        this.form.isShowVideoId = true;
                        this.form.isShowDuration = true;
                    }
                    this.form.dateTime=[this.lesson.startTime,this.lesson.endTime];
                    //设置时间、教师控件状态
                    if(this.lesson.type == LessonTypeEnum.LIVE){
                      var lessonTime = new Date(this.lesson.endTime);
                      var currentTime = new Date();
                      if(lessonTime > currentTime){
                        this.form.disableBaiJiaInfo=false;
                      }
                      else{
                        this.form.disableBaiJiaInfo=true;
                      }
                    }
                }).catch((res) => {
                    if(res.response != null){
                        const data = res.response.data;
                        this.showMsgError(data.message);
                    }
                });
            },
            getTeachers:function(){
                this.$http({
                    method: "get",
                    url: UrlEnum.TEACHER+'?page=1&size=100',
                }).then((res) => {
                    this.form.teachers = res.data.list;
                }).catch((res) => {
                    if(res.response != null){
                        const data = res.response.data;
                        this.showMsgError(data.message);
                    }
                });
            },
            submit: function(){
                if(this.lesson.name == ""){
                    this.showMsgError("请输入 课时名称");
                    return;
                }
                if(this.form.dateTime == null){
                    this.showMsgError("请选择 开始/结束时间");
                    return;
                }
                this.lesson.startTime = this.moment(this.form.dateTime[0]).format("YYYY-MM-DD HH:mm:ss");
                this.lesson.endTime = this.moment(this.form.dateTime[1]).format("YYYY-MM-DD HH:mm:ss");
                if(this.lesson.type == LessonTypeEnum.LIVE || this.lesson.type == LessonTypeEnum.LIVE_SUPPLY){
                    //验证上课时间是否合法
                    if(this.lesson.startTime >= this.lesson.endTime){
                        this.showMsgError("起始时间-结束时间 设置错误");
                        return;
                    }
                }
                console.log(JSON.stringify(this.lesson));
                this.form.loading=true;
                this.$http({
                    method: "put",
                    url: UrlEnum.LESSON + "/" + request('id'),
                    data: this.lesson,
                })
                .then((res) => {
                  this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
                  setTimeout(function () {
                    window.parent.postMessage({
                      msg: '',
                      key: 'refresh'
                    }, '*')
                  }, 800);
                })
                .catch((res) => {
                  alert("ok")
                    this.loading=false;
                    if(res.response != null){
                        const data = res.response.data;
                        this.showMsgError(data.message);
                    }
                });
            }
        },
        mounted() {
            this.initData();
            this.getTeachers();
        }
    }
</script>

<style scoped>
    @import "../../../static/css/add.css";
</style>
